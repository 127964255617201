import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import './partner.css';
import { getAllPartners } from '../../redux/apiCalls/partnerApiCall';
import { Spin } from 'antd';

function Partner() {
    const dispatch = useDispatch();
    const partners = useSelector((state) => state.partner.partners?.partners || []);
    const loading = useSelector((state) => state.partner.loading);

    useEffect(() => {
        dispatch(getAllPartners());
    }, [dispatch]);

    const getDomainName = (url) => {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.hostname.replace('www.', ''); // Retirer 'www.' s'il est présent
        } catch (e) {
            console.error('Invalid URL', url);
            return url; // Retourner l'URL telle quelle si l'URL est invalide
        }
    };

    const renderCarousel = () => {
        if (loading) {
            return <Spin size="large" />;
        }

        if (!partners.length) {
            return <p>No partners available</p>;
        }

        const groupedPartners = [];
        for (let i = 0; i < partners.length; i += 3) {
            groupedPartners.push(partners.slice(i, i + 3));
        }

        return groupedPartners.map((group, index) => (
            <div className="carousel-client" key={index}>
                {group.map((partner) => (
                    <div className="slide" key={partner._id}>
                        <a
                            href={partner.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={getDomainName(partner.website)}
                        >
                            <img
                                src={partner.image?.url || 'assets/assets/img/default-image.jpg'}
                                alt={partner.name}
                            />
                            {/* <span>{getDomainName(partner.website)}</span> */}
                        </a>
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            <Header title1={"Our Partners"}
                bgImg="assets/assets/img/bg-header.jpg"
            />
            <section className="client">
                <div className="_container">
                    <div className="row">
                        <div className="section-title">
                            <h2>They trust us:</h2>
                        </div>
                        {renderCarousel()}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Partner;









// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import Header from '../../components/Header';
// import './partner.css';
// import { getAllPartners } from '../../redux/apiCalls/partnerApiCall';
// import { Spin } from 'antd';

// function Partner() {
//     const dispatch = useDispatch();
//     const partners = useSelector((state) => state.partner.partners?.partners || []);
//     const loading = useSelector((state) => state.partner.loading);

//     useEffect(() => {
//         dispatch(getAllPartners());
//     }, [dispatch]);

//     const renderCarousel = () => {
//         if (loading) {
//             return <Spin size="large" />;
//         }

//         // Vérifier si la liste des partenaires est vide ou non définie
//         if (!partners.length) {
//             return <p>No partners available</p>;
//         }

//         const groupedPartners = [];
//         for (let i = 0; i < partners.length; i += 3) {
//             groupedPartners.push(partners.slice(i, i + 3));
//         }

//         return groupedPartners.map((group, index) => (
//             <div className="carousel-client" key={index}>
//                 {group.map((partner) => (
//                     <div className="slide" key={partner._id}>
//                         <a href={partner.website} target="_blank" rel="noopener noreferrer">
//                             <img src={partner.image?.url || 'assets/assets/img/default-image.jpg'} alt={partner.name} />
//                         </a>
//                     </div>
//                 ))}
//             </div>
//         ));
//     };

//     return (
//         <>
//             <Header title1={"Our Partners"} />
//             <section className="client">
//                 <div className="_container">
//                     <div className="row">
//                         <div className="section-title">
//                             <h2>They trust us:</h2>
//                         </div>
//                         {renderCarousel()}
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default Partner;
