import { productActions } from "../slices/productSlice";
import request from "../../utils/request";
import { toast } from "react-toastify";

// Get Product Details
export function getProductDetails(productId) {
  return async (dispatch) => {
    try {
      const { data } = await request.get(`/product/${productId}`);
      dispatch(productActions.setProduct(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
}

// Get Products Count (for admin dashboard)
export function getProductsCount() {
  return async (dispatch, getState) => {
    try {
      const { data } = await request.get(
        `/product/count`,
        {
          headers: {
            Authorization: "Bearer " + getState().auth.user.token,
          },
        }
      );

      dispatch(productActions.setProductsCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
}

// Get All Products 
export function getAllProducts() {
  return async (dispatch) => {
    try {
      dispatch(productActions.setLoading());

      const { data } = await request.get(
        `product/all`,
      );

      dispatch(productActions.setProducts(data));
      dispatch(productActions.clearLoading());

    } catch (error) {
      // toast.error(error.response.data.message);
      dispatch(productActions.clearLoading());

    }
  };
}
