import React from 'react';
import './style.css'; // Assurez-vous de créer ce fichier CSS
import { NavLink, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  return (
    <div >
      <nav style={location.pathname === '/details' ? { backgroundColor: "#000" } : {}} className="navbar navbar-expand-lg navbar-dark fixed-top " id="mainNav">
        <div className="container">
          <a className="navbar-brand" href="#page-top">
            <img src="/assets/assets/img/logo-bionic-soul.png" alt="Bionic Soul Logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i className="fas fa-bars ms-1"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto py-4 py-lg-0">
              <li className="nav-item">
                <NavLink to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} >Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} >About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/products" className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`} >Products</NavLink>
              </li>
              {/* <li className="nav-item">
              <NavLink to="/usage" className={`nav-link ${location.pathname === '/usage' ? 'active' : ''}`} >Usage</NavLink>
            </li> */}
              <li className="nav-item">
                <NavLink to="/service" className={`nav-link ${location.pathname === '/service' ? 'active' : ''}`} >Services</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/partner" className={`nav-link ${location.pathname === '/partner' ? 'active' : ''}`} >Our Partners</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/achievement" className={`nav-link ${location.pathname === '/achievement' ? 'active' : ''}`} >Awards & Recognitions</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} >Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
