
// import $ from 'jquery';
import 'waypoints/lib/jquery.waypoints.min';
import './About.css';
import AnimatedText from '../../utils/AnimatedText';
import Header from '../../components/Header';
import ServiceItem from '../../utils/ServiceItem';

function About() {


  return (
    <>
      <Header title1="About Us"
        bgImg="assets/assets/img/headers/about.jfif"

      />

      <div className="about-section-left company-desription-bg-image">
        <AnimatedText>
          <div className="inner-container-right">
            <h1>Company Profile</h1>
            <p className="text3">
              Bionic Soul is a pioneering startup dedicated to developing and manufacturing advanced bionic prosthetics that enhance the mobility and quality of life for amputees. Our cutting-edge products, including BioniLeg, ErgoLeg, and Bionic Arm, leverage the latest in biomechanical engineering and user-centered design to provide high-quality, affordable, and customizable solutions. We are committed to achieving both commercial success and making a significant social impact by making advanced prosthetic technology accessible to all.
            </p>
          </div>
        </AnimatedText>
      </div>

      <section className="stories">
                    <AnimatedText>
                        <h2 className='display-6 text-center' >Stories of Resilience</h2>
                    </AnimatedText>
                    <div className="about-section-left nadine-bg-image">
                        <AnimatedText>

                            <div className="inner-container-right">
                                <h1>Nadine's Story</h1>
                                <p>Nadine, who lost her leg, now uses an advanced prosthetic limb that provides her with natural movement and sensory feedback. This technology has transformed her life, allowing her to pursue her passion for hiking and leading an active lifestyle. Nadine's journey showcases the profound impact of bionic advancements on mobility and independence.</p>


                            </div>
                        </AnimatedText>

                    </div>

                    {/* <AnimatedText  >
                        <div className="mb-5">
                            <YoutubeVideo id="YVmYYxgT0tE" />
                        </div>
                    </AnimatedText> */}


                    <div className="about-section-left hassen-bg-image">
                        <AnimatedText>

                            <div className="inner-container-right">
                                <h1 >Hassen's Story</h1>
                                <p>Hassen, who lost his arm, has adapted to using a state-of-the-art prosthetic arm that integrates seamlessly with smart technology. This has enabled him to perform daily tasks with ease and even pursue his hobby of playing the guitar. Hassen's experience highlights the possibilities of advanced prosthetics in enhancing functionality and quality of life.</p>
                                <a href="https://www.linkedin.com/posts/nada-ghammem_succaeys-inspirant-innovation-ugcPost-7210388717964849153-ZNpD?utm_source=share&utm_medium=member_desktop" className="btn btn-primary">Explore More</a>

                            </div>

                        </AnimatedText>

                    </div>

                </section>

      <section className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <ServiceItem
              delay={0.1}
              icon="fa-dollar-sign"
              title="Prix abordables"
              description="Nos produits sont proposés à des prix compétitifs pour garantir l'accessibilité."
            />
            <ServiceItem
              delay={0.3}
              icon="fa-robot"
              title="Intégration de l'IA pour un contrôle précis"
              description="Nous utilisons l'intelligence artificielle pour offrir un contrôle précis et réactif de nos prothèses."
            />
            <ServiceItem
              delay={0.5}
              icon="fa-cogs"
              title="Design ajustable pour un confort optimal"
              description="Nos prothèses sont conçues pour s'ajuster parfaitement, offrant un confort optimal."
            />
            <ServiceItem
              delay={0.7}
              icon="fa-medal"
              title="Matériaux de haute qualité et durables"
              description="Nous utilisons des matériaux de haute qualité pour assurer la durabilité et la longévité de nos produits."
            />
            <ServiceItem
              delay={0.9}
              icon="fa-headset"
              title="Support client et maintenance"
              description="Notre équipe est disponible pour offrir un support client et des services de maintenance efficaces."
            />
          </div>
        </div>
      </section>

      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">

      </div>

    </>
  );
}

export default About;
