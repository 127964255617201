import React, { BrowserRouter, Routes, Route } from 'react-router-dom'

import About from './pages/about/About'
// import Team from './pages/team/Team'
import Contact from './pages/contact/Contact'

import Home from './components/Home'
import Presentation from './pages/presentation/Presentation';
import Achievements from './pages/achievement/Achievements'
import Service from './pages/serv/Service'
import Product from './pages/product/Product';
import Team from './components/team/Team';
import Usage from './pages/usage/Usage';
import NotFound from './components/notfound/NotFound';
import Partner from './pages/partner/Partner';
import ScrollToTop from './utils/ScrollToTop';
// import ProductDetail from './components/detail/ProductDetail';



function App() {
  return (
    <>

      <BrowserRouter >
      <ScrollToTop />

        <Routes>
          <Route path="/*" element={<NotFound />} />
          <Route path="/" element={<Home />}>
            <Route path="/" element={<Presentation />} />
            <Route path="about" element={<About />} />
            <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />
            <Route path="achievement" element={<Achievements />} />
            <Route path="service" element={<Service />} />
            <Route path="products" element={<Product />} />
            <Route path="usage" element={<Usage />} />
            <Route path="partner" element={<Partner />} />
            {/* <Route path="products/:productId" element={<ProductDetail />} /> */}



            {/* <Route path="galerie" element={<Galerie />} /> */}
            {/* <Route path="contact" element={<Contact />} />
            <Route path="contact" element={<Contact />} />
            <Route path="contact" element={<Contact />} /> */}

          </Route>

        </Routes>
      </BrowserRouter>


    </>
  )
}

export default App