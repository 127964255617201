import { partnerActions } from "../slices/partnerSlice";
import request from "../../utils/request";
import { toast } from "react-toastify";

// Get Partner Details
export function getPartnerDetails(partnerId) {
  return async (dispatch) => {
    try {
      const { data } = await request.get(`/partner/${partnerId}`);
      dispatch(partnerActions.setPartner(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
}

// Get Partners Count 
export function getPartnersCount() {
  return async (dispatch, getState) => {
    try {
      const { data } = await request.get(
        `/partner/count`,
        {
          headers: {
            Authorization: "Bearer " + getState().auth.user.token,
          },
        }
      );

      dispatch(partnerActions.setPartnersCount(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
}

// Get All Partners 
export function getAllPartners() {
  return async (dispatch, getState) => {
    try {
      dispatch(partnerActions.setLoading());

      const { data } = await request.get(
        `/partner/all`,

      );

      dispatch(partnerActions.setPartners(data));
      dispatch(partnerActions.clearLoading());

    } catch (error) {
      // toast.error(error.response.data.message);
      dispatch(partnerActions.clearLoading());

    }
  };
}
