import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from 'antd';

const AchievementSection = ({ title, date, index, imgSrc }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`my-section ${inView ? 'show-me' : ''} ${index % 2 === 0 ? 'left' : 'right'}`}
    >
      <div className="my-bead"></div>
      <div className="my-content">
        <h2 className='text2'>{title}</h2>
        <Image
          src={imgSrc}
          alt={title}
          className="achievement-img"
          // preview={false} // Disable preview if not needed
          style={{ maxWidth: '100%', height: 'auto' }} // Optional: Adjust styles if needed
        />
        <p className='text3'>{date }</p>
      </div>
    </div>
  );
};

export default AchievementSection;
