import { configureStore } from "@reduxjs/toolkit";
import { productReducer } from "./slices/productSlice";
import { partnerReducer } from "./slices/partnerSlice";
import { contactReducer } from "./slices/contactSlice";
import { awardReducer } from "./slices/awardSlice";

const store = configureStore({
    reducer: {

        product: productReducer,
        partner: partnerReducer,
        contact: contactReducer,
        award: awardReducer
    },
});

export default store;