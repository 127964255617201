import React from 'react'
import { useInView } from 'react-intersection-observer';





const ServiceItem = ({ delay, icon, title, description }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
    return (
      <div
        className={`col-lg-3 col-sm-6 service-item text-center pt-3 wow fadeInUp ${inView ? 'animate' : ''}`}
        ref={ref}
        style={{ animationDelay: `${delay}s` }}
      >
        <div className="p-4">
          <i className={`fa fa-3x ${icon} text-warning mb-4 `}></i>
          <h5 className="mb-3 ">{title}</h5>
          <p className='text-muted'>{description}</p>
        </div>
      </div>
    );
  };
  
  export default ServiceItem