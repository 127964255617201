import React from 'react'
import Header from '../../components/Header'

function Usage() {
  return (
    <>
          <Header
        title1="Usage"
      // title2="Advanced bionic prosthetics for a better quality of life"
      />
    </>
  )
}

export default Usage