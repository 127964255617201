import React from 'react';
import { useInView } from 'react-intersection-observer';
import './Team.css';

const instructors = [
  {
    img: "assets/assets/img/team/nadaGH.png",
    name: "NADA GHAMMEM",
    designation: "CEO & PRODUCT DESIGNER",
    delay: "0.1s",
    fb: "https://www.facebook.com/nada.ghammem",
    insta: "https://www.instagram.com/nada.ghammem/",
    linkedin: "https://www.linkedin.com/in/nada-ghammem/",
  },
  // {
  //   img: "assets/assets/img/team/AchrefSoua.jpeg",
  //   name: "ACHREF SOUA",
  //   designation: "CTO & DATA SCIENTIST",
  //   delay: "0.3s",
  //   fb: "https://www.facebook.com/achraf.soua",
  //   insta: "https://www.instagram.com/achref_soua/",
  //   linkedin: "https://www.linkedin.com/in/achref-soua/",
  // },
  // {
  //   img: "assets/assets/img/team/NaccacheHoussem.jpg",
  //   name: "HOUSSEM NACCACHE",
  //   designation: "BIOMEDICAL EXPERT",
  //   delay: "0.5s",
  //   fb: "https://www.facebook.com/naccachehoussem",
  //   insta: "https://www.instagram.com/houssem_naccache/",
  //   linkedin: "",
  // },
];

function Team() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });

  const refs = [ref1, ref2, ref3, ref4];
  const views = [inView1, inView2, inView3, inView4];

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">Bionic Soul</h6>
          <h1 className="mb-5">OUR TEAM</h1>
        </div>
        <div className="row g-4">
          {instructors.map((instructor, index) => (
            <div
              className={`col-lg-3 col-md-6 ${views[index] ? 'animate-instructor' : 'initial'} wow fadeInUp`}
              data-wow-delay={instructor.delay}
              ref={refs[index]}
              key={index}
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={instructor.img} alt={instructor.name} />
                </div>
                <div className="position-relative d-flex justify-content-center" style={{ marginTop: -23 }}>
                  <div className="bg-light d-flex justify-content-center pt-2 px-1">
                    <a className="btn btn-sm-square btn-primary mx-1" href={instructor.fb}><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-sm-square btn-primary mx-1" href={instructor.linkedin}><i className="fab fa-linkedin"></i></a>
                    <a className="btn btn-sm-square btn-primary mx-1" href={instructor.insta}><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">{instructor.name}</h5>
                  <small>{instructor.designation}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
