import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    product: null,
    loading: false,
    productsCount: null,
    isProductCreated: false,
    products: [],
  },
  reducers: {
    setProduct(state, action) {
      state.product = action.payload;
    },

    setLoading(state) {
      state.loading = true;
    },
    clearLoading(state) {
      state.loading = false;
    },
    setProductsCount(state, action) {
      state.productsCount = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
  },
});

const productReducer = productSlice.reducer;
const productActions = productSlice.actions;

export { productActions, productReducer };
