import React from 'react';
import { useInView } from 'react-intersection-observer';
import './service.css'; // Assurez-vous de créer ce fichier pour les styles
import Header from '../../components/Header';

const ServiceItem = ({ delay, icon, title, description }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div
            className={`col-lg-3 col-sm-6 service-item text-center pt-3 wow fadeInUp ${inView ? 'animate' : ''}`}
            ref={ref}
            style={{ animationDelay: `${delay}s` }}
        >
            <div className="p-4">
                <i className={`fa fa-3x ${icon} text-primary mb-4`}></i>
                <h5 className="mb-3">{title}</h5>
                <p>{description}</p>
            </div>
        </div>
    );
};

const Services = () => {
    return (
        <>
            <Header
                title1="Services"
                bgImg="assets/assets/img/headers/services.jpg"

            // title2="Advanced bionic prosthetics for a better quality of life"
            />
            <section className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4">
                        <ServiceItem
                            delay={0.1}
                            icon="fa-cogs"
                            title="Development of Innovative Solutions"
                            description="We develop cutting-edge bionic and robotic solutions aimed at enhancing quality of life through the integration of advanced technologies."
                        />

                        <ServiceItem
                            delay={0.4}
                            icon="fa-heart"
                            title="Specialized Training and Awareness"
                            description="We offer specialized training programs to educate and raise awareness among professionals and the public about the adoption and use of bionic technologies."
                        />

                        <ServiceItem
                            delay={0.5}
                            icon="fa-wrench"
                            title="Proactive Maintenance and Technical Support"
                            description="We provide proactive maintenance services and ongoing technical support to ensure the optimal functioning of deployed bionic solutions."
                        />

                        <ServiceItem
                            delay={0.6}
                            icon="fa-hands-helping"
                            title="Partnerships and Collaborations"
                            description="We collaborate with various institutions and businesses to develop innovative bionic projects, offering a unique synergy between technical expertise and strategic vision."
                        />

                        <ServiceItem
                            delay={0.8}
                            icon="fa-globe"
                            title="International Expansion"
                            description="We assist businesses in their international expansion by providing bionic solutions tailored to global markets and complying with local standards."
                        />

                        <ServiceItem
                            delay={0.4}
                            icon="fa-shield-alt"
                            title="Extended Warranty and Insurance Programs"
                            description="We offer insurance programs that cover repair, replacement, or updates of prostheses. In partnership with insurance companies, these warranties extend to material defects, accidents, and premature wear."
                        />

                        <ServiceItem
                            delay={0.5}
                            icon="fa-brain"
                            title="Machine Learning Platform for Personalized Adaptation"
                            description="A cloud platform that analyzes sensor data from the prosthesis to adjust control algorithms based on individual needs. The more the user uses the prosthesis, the better it performs and adapts to their preferences."
                        />

                        <ServiceItem
                            delay={0.6}
                            icon="fa-heartbeat"
                            title="Real-Time Health Monitoring System for the Prosthesis"
                            description="This system integrates smart sensors that monitor the prosthesis's condition and the user's health in real time. Collected data is transmitted to a mobile app or service center for continuous monitoring and preventive interventions."
                        />

                        <ServiceItem
                            delay={0.7}
                            icon="fa-paint-brush"
                            title="Prosthesis Customization"
                            description="We offer prosthesis customization services to meet the specific needs of users, whether for functional or aesthetic adaptation."
                        />

                        <ServiceItem
                            delay={0.8}
                            icon="fa-code"
                            title="Reprogramming and Software Updates"
                            description="We provide reprogramming services to enhance prosthesis performance and regular software updates to ensure optimal functionality."
                        />

                        <ServiceItem
                            delay={0.9}
                            icon="fa-home"
                            title="Consultation for Environmental Adaptation"
                            description="We offer advice on adapting the prosthesis to the user's daily environment, including recommendations for adjusting home or workplace settings."
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;