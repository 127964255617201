import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
// import Header from './Header';
// import Header from './Header';
// import Contact from '../pages/contact/Contact';


function Home() {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>
    )
}

export default Home