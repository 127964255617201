import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import AchievementSection from '../../utils/AchievementSection';
import AnimatedText from '../../utils/AnimatedText';
import { getAllAwards } from '../../redux/apiCalls/awardApiCall'; // Importez l'action pour récupérer les awards
import { Spin } from 'antd'; // Importer le composant Spin
import './achievements.css';

// Fonction pour formater la date
const formatDate = (dateStr) => {
  if (!dateStr) return 'No date';

  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long'
  });
};

const Achievements = () => {
  const dispatch = useDispatch();
  const awards = useSelector((state) => state.award.awards);
  const { loading, error } = useSelector((state) => state.award); // Gérez les erreurs et l'état de chargement

  useEffect(() => {
    dispatch(getAllAwards()); // Récupérer les awards lors du premier rendu du composant
  }, [dispatch]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Header title1="Awards & Recognitions"
        bgImg="assets/assets/img/headers/home.webp"
      />
      <div className="my-container">
        <AnimatedText>
          <div className="top-section">
            <h1 className='text1 text-center'>Awards and Recognitions</h1>
            <p className='text3'>
              Bionic Soul is proud to have received numerous accolades for our contributions to the field
              of bionic technology and healthcare innovation. Our dedication to advancing prosthetics and
              empowering individuals with amputations has been recognized through various prestigious awards.
            </p>
          </div>
          <h3 className='text1 text-center pb-5'>Key Achievements and Awards:</h3>
        </AnimatedText>
        <div className="timeline">
          {loading ? (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="line"></div>
              {awards.map((award, index) => (
                <AchievementSection
                  key={index}
                  title={award.title}
                  date={formatDate(award.date)} // Formatage de la date pour afficher uniquement le mois et l'année
                  index={index}
                  imgSrc={award.image.url}
                />
              ))}
            </>
          )}
        </div>
        <div className="bottom-section">
          <p className='text3 text-center pt-5'>
            These achievements highlight our commitment to innovation, excellence, and making a positive
            impact on the lives of individuals living with amputations. We are honored to be recognized
            and motivated to continue our journey in redefining possibilities through bionic technology.
          </p>
        </div>
      </div>
    </>
  );
};

export default Achievements;
