import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../pages/presentation/presentation.css';

const AnimatedText = ({ children }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div ref={ref} className={`animated-text ${inView ? 'animate-text' : ''}`}>
            {children}
        </div>
    );
};

export default AnimatedText;
