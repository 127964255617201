import React from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom';

function Header({ title1, title2, bgImg }) {
    const location = useLocation();

    return (
        
        <header className="masthead" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="">
                {/* <motion.div className="masthead-subheading  fade-in glow- handwriting-title " */}

                <motion.div className="masthead-subheading  fade-in handwriting-title "
                    initial={{ y: "10rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring",
                    }}
                > 
                {/* {title1} */}
                    {/* Empowering Mobility Enhancing Lives */}
                </motion.div>
                <motion.div className="masthead-subheading text-uppercase  fade-in "
                    initial={{ y: "10rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 4,
                        type: "spring",
                    }}
                >
                    {/* {title2} */}
                    {/* Advanced bionic prosthetics for a better quality of life */}
                </motion.div>
                {location.pathname === '/' && (
                    <Link to="/products">
                        <motion.a className="btn btn-primary btn-xl text-uppercase"
                            initial={{ y: "10rem", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                duration: 3,
                                type: "spring",
                            }}
                        >Explore Our Products
                        </motion.a>
                    </Link>
                )}
            </div>
        </header>
    )
}

export default Header
