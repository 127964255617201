import { createSlice } from "@reduxjs/toolkit";

const partnerSlice = createSlice({
    name: "partner",
    initialState: {
        partner: null,
        loading: false,
        partnersCount: null,
        isPartnerCreated: false,
        partners: [],
    },
    reducers: {
        setPartner(state, action) {
            state.partner = action.payload;
        },
        addPartner(state, action) {
            state.partners.part.push(action.payload);
        },

        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setPartnersCount(state, action) {
            state.partnersCount = action.payload;
        },
        setPartners(state, action) {
            state.partners = action.payload;
            // console.log(state.partners)
        },

    },
});

const partnerReducer = partnerSlice.reducer;
const partnerActions = partnerSlice.actions;

export { partnerActions, partnerReducer };
