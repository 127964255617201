import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../../redux/apiCalls/productApiCall';
import { Modal, Image, Row, Col, message, Flex, Spin } from 'antd'; // Import Modal component from Ant Design

import './Products.css';
import Header from '../../components/Header';

const Products = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const loading = useSelector((state) => state.product.loading);
    // const [isLoading, setIsLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null); // State to store selected product for modal

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                dispatch(getAllProducts());
                // setIsLoading(false);
            } catch (error) {
                message.error('Erreur lors de la récupération des produits');
                // setError(error);
                // setIsLoading(false);
            }
        };

        fetchProducts();
    }, [dispatch]);

    const handleProductClick = (product) => {
        setSelectedProduct(product); // Update selected product state on click
    };

    const handleModalClose = () => {
        setSelectedProduct(null); // Clear selected product when modal closes
    };

    return (
        <>
            <Header title1="Products"
                bgImg="assets/assets/img/headers/productss.jpg"
            />
            <section>
                {loading ? (
                    <Flex align="center" justify="center" style={{ height: '100vh' }}>
                        <Spin size="large" />
                    </Flex>
                ) : (
                    <>
                        <h2 className='text1 text-center'>Our Products</h2>
                        <div className="container">
                            {products.map((product) => (
                                <div className="" key={product._id}>
                                    <div className="card-inner" style={{ color: '#fff' }}>
                                        <div className="box">
                                            <div className="imgBox">
                                                <img
                                                    src={product.image.url || 'default-image-url.jpg'}
                                                    alt={product.title}
                                                    className="img-fluid"
                                                    onClick={() => handleProductClick(product)} // Handle click on product card
                                                />
                                                {product.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </section>

            {/* Modal for product details */}
            <Modal loading={loading}
                visible={selectedProduct !== null} // Show modal when selected product exists
                onCancel={handleModalClose}
                title={selectedProduct?.title} // Set modal title based on selected product
                footer={null} // Remove default footer for a cleaner look (optional)
            >
                {/* Display product details within the modal */}
                <Row>
                    <Col span={12}>
                        <h3>{selectedProduct?.title}</h3>
                        <p>{selectedProduct?.description}</p>
                        {/* Add additional details as needed (e.g., price, availability) */}
                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                            src={selectedProduct?.image.url || 'default-image-url.jpg'}
                            alt={selectedProduct?.title}
                            style={{ maxWidth: '200px', display: 'block', margin: '0 auto' }} // Center and style image
                        />
                    </Col>
                </Row>

            </Modal>
        </>
    );
};

export default Products;