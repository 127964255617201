import AnimatedText from '../../utils/AnimatedText';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import counterUp from 'counterup2';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion'




import './presentation.css';

// import Team from '../../components/team/Team';
// import Header from '../../components/Header';
import ServiceItem from '../../utils/ServiceItem';
// import YoutubeVideo from '../../utils/YoutubeVideo';

function Presentation() {
    const location = useLocation();


    const { ref: happyClientsRef, inView: happyClientsInView } = useInView({ triggerOnce: true });
    const { ref: projectsDoneRef, inView: projectsDoneInView } = useInView({ triggerOnce: true });


    useEffect(() => {
        if (happyClientsInView) {
            const el = document.querySelector('[data-counter="happyClients"]');
            counterUp(el, {
                duration: 2000,
                delay: 16,
            });
        }
        if (projectsDoneInView) {
            const el = document.querySelector('[data-counter="projectsDone"]');
            counterUp(el, {
                duration: 2000,
                delay: 16,
            });
        }
    }, [happyClientsInView, projectsDoneInView]);


    // const videoUrl = 'https://www.linkedin.com/posts/7114771853490946048'; // URL de votre vidéo LinkedIn
    // const previewImageUrl = 'URL_DE_L_IMAGE_DE_PREVISUALISATION'; // Remplacez par l'URL de l'image de prévisualisation

    return (

        <>
            {/* <Header
                // title1="Advanced bionic prosthetics"
                // title2="Advanced bionic prosthetics for a better quality of life"
                bgImg="assets/assets/img/headers/home.jpg"
            /> */}
            <div className="video-container">
                <video className="bg-video" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                    <source src="assets/assets/videos/nadineVideo.mp4" type="video/mp4" />
                </video>
                <div className="overlay-content">
                    <h1>Welcome to Bionic Soul</h1>
                    <p>Redefining possibilities with bionic prosthetics.</p>
                    {location.pathname === '/' && (
                    <Link to="/products">
                        <motion.a className="btn btn-primary btn-xl text-uppercase"
                            initial={{ y: "10rem", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                duration: 3,
                                type: "spring",
                            }}
                        >Explore Our Products
                        </motion.a>
                    </Link>
                )}
                    {/* <a href="https://www.linkedin.com/posts/bionic-soul_innovation-technologie-bionicsoul-activity-7114771853490946048-Q6iX/?utm_source=share&utm_medium=member_desktop" className="btn btn-primary">Explore More</a> */}
                </div>
            </div>
            <div className="container about px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 ps-µ-0 d-flex align-items-center" style={{ minHeight: '400px' }}>
                        <video
                            className="w-100"
                            style={{ objectFit: 'cover', maxHeight: '100%' }}
                            playsInline
                            autoPlay
                            muted
                        // If you want the video to loop
                        >
                            <source src="assets/assets/videos/statistic.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 pe-lg-0">
                            <div className="section-title text-start">
                                <h1 className="display-5 mb-4">Statistics</h1>
                            </div>
                            {/* <p className="text3 mb-4 pb-2">
                                • Approximately 65 million people worldwide live with limb amputations. <br />
                                • Over 10,000 individuals in the UAE alone live with limb amputations. <br />
                                • The main causes of limb loss include vascular disease, trauma, diabetes and cancer. <br />
                                • Globally, an estimated 185,000 new lower limb amputations occur each year due to diabetes. <br />
                                • Limb loss can significantly impact an individual's quality of life and ability to perform daily activities.
                            </p> */}
                            <div className="row g-4 mb-4 pb-2">
                                <div className="col-sm-12 wow fadeIn" data-wow-delay="0.1s" ref={happyClientsRef}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white icon-box">
                                            <i className="fa fa-users fa-2x text-primary"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h2 className="text-primary mb-1" data-counter="happyClients">65 M</h2>
                                            <p className="text3 fw-medium mb-0">People living with limb amputation</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 wow fadeIn" data-wow-delay="0.3s" ref={projectsDoneRef}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white icon-box">
                                            <i className="fa fa-check fa-2x text-primary"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h2 className="text-primary mb-1" data-counter="projectsDone">10000$ - 70000$</h2>
                                            <p className="text3 fw-medium mb-0">The price range of leg prosthetics</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <a href="c" className="btn btn-primary py-3 px-5">Explore More</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <AnimatedText>
                <section className="sdg-container">
                    <h1 className='text1 text-center'>Objectifs de Développement Durable</h1>
                    <div className="sdg-grid">
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/1.jpeg" alt="SDG 1" />
                            <h2>Objectif 1</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/3.jfif" alt="SDG 2" />
                            <h2>Objectif 2</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/5.png" alt="SDG 3" />
                            <h2>Objectif 3</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/7.png" alt="SDG 4" />
                            <h2>Objectif 4</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/8.png" alt="SDG 3" />
                            <h2>Objectif 5</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/9.png" alt="SDG 4" />
                            <h2>Objectif 6</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/10.png" alt="SDG 5" />
                            <h2>Objectif 7</h2>
                        </div>
                        <div className="sdg-item">
                            <img src="assets/assets/img/goal/17.png" alt="SDG 5" />
                            <h2>Objectif 8</h2>
                        </div>
                    </div>
                </section>
            </AnimatedText>


            <div className="containe">
                <AnimatedText>
                    <section className="intr">
                        <p className='text1 text-center'>Living with an Amputation and Embracing a Bionic Soul</p>
                        <p className='text1 text-center'>Discovering New Possibilities</p>
                    </section>
                </AnimatedText>

                
                <p className='text1 display-6 text-center mb-4' >Key Features of Advanced Prosthetics</p>

                <section className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-4">
                            <ServiceItem
                                delay={0.1}
                                icon="fa-running"
                                title="Natural Movement"
                                description="Connects with smart devices for real-time data and control."
                            />
                            <ServiceItem
                                delay={0.3}
                                icon="fa-hand-paper"
                                title="Sensory Feedback"
                                description="Technology that allows users to feel pressure and texture."
                            />
                            <ServiceItem
                                delay={0.5}
                                icon="fa-user-cog"
                                title="Custom Fit and Comfort"
                                description="Tailored designs for a comfortable fit."
                            />
                            <ServiceItem
                                delay={0.7}
                                icon="fa-wifi"
                                title="Integration with Smart Technology"
                                description="Connects with smart devices for real-time data and control."
                            />
                        </div>
                    </div>
                </section>

                {/* <div className="ecosystem-container">
                    <div className="circle-container">
                        <div className="circle b2b">
                            <h3>B2B</h3>
                            <p>Orthopedic center</p>
                        </div>
                        <div className="circle b2g">
                            <h3>B2G</h3>
                            <p>Ecosystem</p>
                        </div>
                        <div className="circle b2c">
                            <h3>B2C</h3>
                            <p>Patients</p>
                        </div>
                    </div>

                    <div className="link-container">
                        <div className="link-item b2b-item">
                            <h4>Personalized Services</h4>
                            <h4>Affordable Price</h4>
                        </div>
                        <div className="link-item b2g-item">
                            <h4>Governments</h4>
                            <h4>Clinics</h4>
                            <h4>Universities & Training Centers</h4>
                        </div>
                        <div className="link-item b2c-item">
                            <h4>Accessibility</h4>
                            <h4>Maintenance & Customer Service</h4>
                        </div>
                    </div>
                </div> */}


                <div className="container py-5">
                    <AnimatedText>
                        <section className="text-center mb-5">
                            <h2 className="display-4 text-primary mb-3">Bionic Soul’s Commitment</h2>
                            <p className="lead mx-auto text-muted" style={{ maxWidth: '800px' }}>
                                At Bionic Soul, our mission is to support and inspire those living with amputations by showcasing the latest advancements in bionic technology. We believe in a future where technology and humanity work together harmoniously, creating a world where limitations are redefined.
                            </p>
                        </section>
                    </AnimatedText>
                    <AnimatedText>

                        <section className="text-center">
                            <h2 className="display-5 text-center text-primary mb-4">Join Our Community</h2>
                            <ul className="lead mx-auto text-muted">
                                <p className="mb-3"><strong>Stay Informed:</strong> Access up-to-date information on bionic innovations.</p>
                                <p className="mb-3"><strong>Connect with Experts:</strong> Engage with professionals in prosthetics and bionics.</p>
                                <p><strong>Share Your Journey:</strong> Connect with others and share your story.</p>
                            </ul>
                        </section>
                    </AnimatedText>
                    <AnimatedText>

                        <section className="text-center">
                            <h2 className="display-4 text-primary mb-3">Our Vision</h2>
                            <p className="lead mx-auto text-muted" style={{ maxWidth: '800px' }}>
                                Bionic Soul envisions a world where technology empowers individuals to overcome physical limitations and lead enriched, empowered lives. We are dedicated to advancing the field of prosthetics and bionics, ensuring everyone has the opportunity to live to their fullest potential.
                            </p>
                        </section>
                    </AnimatedText>

                </div>



            </div>


            {/* <Team></Team> */}

        </>
    );
}

export default Presentation;
