import React from 'react'
import './notfound.css'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div id='oopss'>
            <Link to="/" class="text-center ">
                ... Back to home page
            </Link>
            <div id='error-text'>
                <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404" />

                <span>404 PAGE</span>
                <p class="p-a">
                    . The page you were looking for could not be found
                </p>


            </div>
        </div>
    )
}

export default NotFound
